import React, { ReactNode } from "react"
import ReactGA from "react-ga"
import { graphql } from "gatsby"
import { NoSideBarLayout } from "../components/layouts"
import SEO from "../components/seo"
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer"
import {
  Block,
  Inline,
  Text,
  Document,
  BLOCKS,
  INLINES,
} from "@contentful/rich-text-types"
import useContentFulImage from "../utils/useContentFulImage"
import Img from "gatsby-image"

interface BasicPageProps {
  data: {
    page: {
      title: string
      subtitle: string
      body: {
        json: Document
      }
    }
  }
}

const options: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline): ReactNode => {
      if (node.data.target.sys.type !== "Asset") {
        return
      }
      const fluid = useContentFulImage(
        node.data.target.fields.file["en-GB"].url
      )
      return (
        <Img
          title={node.data.target.fields.title["en-GB"]}
          fluid={fluid}
          style={{ maxWidth: 300 }}
        />
      )
    },
    [INLINES.HYPERLINK]: (node, children): ReactNode => (
      <ReactGA.OutboundLink
        eventLabel={
          ("Generic Link to " + (node.content[0] as Text).value) as string
        }
        to={node.data.uri}
        target="_blank"
      >
        {children}
      </ReactGA.OutboundLink>
    ),
  },
}

const ArticleTemplate: React.FC<BasicPageProps> = ({ data }) => {
  const { body, subtitle, title } = data.page
  return (
    <NoSideBarLayout>
      <SEO title={title} description={subtitle} />
      <div id="content">
        <article>
          <h1 style={{ marginBottom: "0.5em" }}>{title}</h1>
          {subtitle && (
            <strong style={{ marginBottom: "1em" }}>{subtitle}</strong>
          )}
          {body && documentToReactComponents(body.json, options)}
        </article>
      </div>
    </NoSideBarLayout>
  )
}

export default ArticleTemplate

export const BasicPageQuery = graphql`
  query BasicPageQuery($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      title
      subtitle
      body {
        json
      }
    }
  }
`
